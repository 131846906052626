



































import {defineComponent, PropType} from '@vue/composition-api';
import STextarea from '@/common/components/STextarea.vue';
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import TableInput from '@/tasks/components/inputs/TableInput.vue';
import {VTextField} from 'vuetify/lib/components';
import CalculationInput from '@/tasks/components/inputs/CalculationInput.vue';

export default defineComponent({
  name: 'uOttawa1321Lab2Question6',
  components: {TableInput, STextarea, CalculationInput, VTextField},
  mixins: [DynamicQuestionMixin()],
  props: {
    isMarking: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  data() {
    const browserLanguage = navigator.language.split('-')[0]; // Extract the language code
    return {
      inputs: {
        aiQuestionResponse: null,
        language: browserLanguage === 'fr' ? 'fr' : 'en', // Set the initial language based on browser preference
      },
      questions: [
        {
          en: 'You are preparing a compound that is reported to be yellow. When you dissolve the impure sample in hot solvent, you get a yellow solution. Is it necessary to use decolorizing carbon before allowing the hot solution to cool? Why or why not?',
          fr: 'Vous préparez un composé supposé d’être jaune. En dissolvant l’échantillon impur dans un solvant chaud, la solution formée est jaune. Est-il nécessaire d’utiliser le noir de charbon avant laissez refroidir la solution chaude ? Expliquez la réponse.',
        },
      ],
    };
  },
  methods: {
    getQuestionText(question: any): string {
      return this.inputs.language === 'en' ? question.en : question.fr;
    },
  },
});
